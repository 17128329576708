import React from 'react';

export default props => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      height: props.small ? 24 : 48,
      fontSize: props.small ? 16 : 32,
      textTransform: 'uppercase',
      color: '#C8A2C8',
      justifyContent: 'center',
    }}
  >
    <svg
      height="100%"
      fill="#C8A2C8"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path d="M91.478,56.945c-0.95,0.821-2.065,1.298-3.274,1.298c-3.542,0-6.316-4.018-6.316-9.147 c0-5.128,2.774-9.146,6.316-9.146c0.375,0,0.739,0.054,1.095,0.141L89.17,28.552L75.364,20.06L2.209,19.876 c0,0,3.497,48.884,4.049,51.461c0.282,1.314,0.476,2.078,5.063,3.744c-1.191,0.686-2.026,2.232-2.026,4.038 c0,2.44,1.52,4.418,3.393,4.418c1.875,0,3.394-1.978,3.394-4.418c0-1.017-0.274-1.94-0.717-2.687 c5.008,1.574,12.87,3.801,25.314,7.236c10.676,2.948,17.107,4.79,21.449,5.546c-0.569,0.917-0.925,2.068-0.925,3.34 c0,2.949,1.837,5.34,4.102,5.34c2.266,0,4.102-2.391,4.102-5.34c0-1.227-0.33-2.342-0.863-3.243 c1.668-0.432,3.035-1.331,4.717-2.697c0,0,5.898-3.674,11.06-7.808c-0.229,0.596-0.371,1.258-0.371,1.969 c0,2.44,1.52,4.418,3.393,4.418c1.875,0,3.394-1.978,3.394-4.418c0-2.439-1.519-4.417-3.394-4.417c-0.053,0-0.102,0.017-0.153,0.021 C92.348,71.695,91.201,70.273,91.478,56.945z" />
      <path d="M87.249,2.678c0,0-56.325-1.289-65.529,0S0,14.458,0,14.458v2.949l76.021,0.184l15.462,9.658v17.097 c-0.79-1.467-1.938-2.396-3.218-2.396c-2.384,0-4.316,3.2-4.316,7.146c0,3.948,1.933,7.147,4.316,7.147 c5.735,0,9.091-27.257,11.317-37.735C102.712,3.782,87.249,2.678,87.249,2.678z" />
    </svg>
    <span style={{ marginLeft: props.small ? 8 : 16 }}>Dumpster</span>
  </div>
);
